<script lang="ts" setup>
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/vue-splide'
import { MediaImageFragment, ParagraphCarousel, ParagraphCarouselItem } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphCarousel
}>()

const slides = computed(
  () =>
    props.entity.fieldCarouselItems?.map((item) => {
      const entity = item?.entity as ParagraphCarouselItem
      return {
        image: entity.fieldImage?.entity as MediaImageFragment,
        caption: entity.fieldDescription?.processed,
      }
    }),
)

const sliderOptions = {
  type: 'loop',
  pagination: false,
  mediaQuery: 'min',
  gap: '10%',
  breakpoints: {
    768: {
      gap: '20%',
    },
  },
} as Options
</script>

<template>
  <Section wide>
    <div class="container grid">
      <div class="tl-10 tl-o-1">
        <Splide :options="sliderOptions" :has-track="false" aria-label="carousel images">
          <SplideTrack class="splide-track">
            <SplideSlide v-for="(slide, index) in slides" :key="index" class="splide-slide">
              <Image :entity="slide.image" type="teaser-single" class="image" />
              <div v-if="slide.caption" class="text-xsmall caption" v-html="slide.caption" />
            </SplideSlide>
          </SplideTrack>
          <div class="splide__arrows">
            <ButtonNavigation class="splide__arrow--prev" />
            <ButtonNavigation class="splide__arrow--next" direction="right" />
          </div>
        </Splide>
      </div>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
.box {
  overflow: hidden;
}
.splide-track {
  overflow: visible;
}

.splide {
  width: 100%;
}
.splide-slide {
  display: flex;
  flex-direction: column;
  gap: 15px;

  align-content: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: auto;
}

.caption {
  color: var(--c-text-light);
}

.splide__arrows {
  justify-content: flex-end;

  display: flex;
  gap: 10px;
}
</style>
